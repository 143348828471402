import { Container, Paper, Typography } from "@material-ui/core";

const image = {
    url: "/images/Rashid_Ma_SaaS_Copywriter.png" ,
    dimensions: {
        width: 280,
      },
}


function Introduction() {
    return (
        <Container maxWidth="md" align="center">
            <Paper component="img" sx={{marginTop: 4}} elevation={0} src={image.url} width={image.dimensions.width} />
            <Typography mt={4} mb={1} component="p" variant="h5" color="textPrimary">
                Hi, I'm Rashid!
            </Typography>

            <Typography component="p" variant="h6" color="textSecondary">
                A software engineer with a knack for persuasive writing.
                I help SaaS companies bolster and expand their market presence by leveraging methods of effective inbound marketing.
            </Typography>
        </Container>
    );
};

export default Introduction;