import { Container, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(6),
    },
}));

function Assurance() {
    const classes = useStyles();
    return (
        <Container className={classes.root} maxWidth="sm">
            <Typography component="h2" variant="h2" align="center" color="textPrimary" gutterBottom>
            Don’t fret!
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" component="p">
            As long as you’ve got a software-related product or service, I can get you compelling copy that weeds out wrong customers and allures the right ones.
            </Typography>
      </Container>
    );
}

export default Assurance;