import { Button, Container } from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom';

function CTA() {
    return (
        <Container align="center" sx={{marginTop: 5}}>
            <Button size="large" component={RouterLink} to="/contact" variant="contained" color="primary">
                Let's Talk
            </Button>
        </Container>
    );
};

export default CTA;