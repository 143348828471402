import { Paper, Grid, Container, Typography, makeStyles } from "@material-ui/core";
import CTA from './CTA';

const useStyles = makeStyles(theme => (
  {
    root: {
      marginTop: theme.spacing(5),
    },
    title: {
      textAlign: "center",
    },
    paper: {
      padding: theme.spacing(3),
      margin: theme.spacing(3),
    }
  }
));

const intro = {
  title: "Another boring page where a self-proclaimed copywriter rambles about his work?",
  image: {
    url: "/images/Rashid_Ma_SaaS_Copywriter.png",
    dimensions: {
      width: 280,
    },
  },
  text: [
    "This isn’t the case, because this page is for YOU! Yes, I’m only here to answer YOUR questions. Otherwise, it’s a waste of your time, isn’t it?",
    "Oh, I’m Rashid, in case I didn’t mention it – a former Software Engineer with a knack for writing. If your product is SaaS or you need a tech copywriter, I am your guy!",
  ],
};
const objections = [
  {
    title: "OK, but I can write my own copy, why do I need you?",
    text: [
      "You’re surely an expert in your field and a master of your product. But writing compelling copy takes more than the expertise in your area. Copywriters often take several days to craft 300-word copy. It takes patience, lots of rewriting, and an ample amount of energy.",
      "Would you want to spend your valuable time on WRITING about your product or IMPROVING it and making it more irresistible to your customers? Delegating this task to someone obsessed with words might save you time and energy.",
    ]
  },
  {
    title: "How are you different from thousands of other copywriters out there?",
    text: [
      "Copywriting is as broad as the universe. There are no more generic copywriters. People choose their niche and specialize in it. Mine is in SaaS and software.",
      "If you’re struggling to connect customers’ pain points with the features of your product, I am your guy.",
      "When targeting a specific audience, it’s important to speak their language. That’s where my software engineering background comes into play. It lets me turn technical features into simple concepts that your audience perceives as benefits and can easily relate to.",
    ]
  },
  {
    title: "What makes you think you can craft compelling copy for me?",
    text: [
      "Tailoring! In the market of multitudes, we’re no longer trying to capture the broadest audience as in the broadcast era. We’re after the fans, people who will love our product and are likely to convert. The catch is that it’s hard to convey the value of our product in the short attention window left for us.",
      "That’s why a short headline needs to be catchy enough to buy an extra second of visitor’s attention. So that our punchy phrasing can separate our audience from the rest of the pack. That’s whom we want to target.",
      "I always keep that in mind when writing a value proposition or brand story. We no longer have the privilege to waste space and need to be extremely concise.",
    ]
  },
  {
    title: "I’m not sure you’ll write better copy than me, after all, you don’t know my product that well…",
    text: [
      "You’re right! I don’t know your product, but that gives me a unique perspective. I can spot things you’ve missed and tap into buyers’ minds.",
      "And here is the thing – I only want to get paid if you love my work! That gives me peace of mind and crystal clear conscience to move forward in life, things far more important than money!",
      "The only thing you risk is twenty minutes of your time answering my questions and reading the copy.",
    ]
  },
  {
    title: "Why does it have to be compelling, what if I just clearly defined the product features?",
    text: [
      "That won’t cut it!",
      "Let me backtrack to my college years. Snatching a glossy diploma wasn’t enough to launch a successful engineering career. I quickly learned that people just DON’T care. They care about THEIR problems!",
      "But I didn’t know. Straight out of college and with no experience, I began pitching my resume. My ravenous inbox begged for replies, but the tough market was relentless. The response rate was lower than that of a Pigeon Post in the heat of a Roman raid.",
      "I had to brush up on my persuasion skills to write memos that prompted a reply. So compelling copy is the only way to induce people to act!",
    ]
  },
]

function About() {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={6} align="center">
          <Paper component="img" elevation={0} src={intro.image.url} width={intro.image.dimensions.width} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography marginBottom={5} align="center" variant="h5" component="h3">
            {intro.title}
          </Typography>
          {intro.text.map(paragraph => (
            <Typography mt={2} variant="subtitle1" component="p">
              {paragraph}
            </Typography>
          ))}
        </Grid>
      </Grid>
      <Grid container>
        {objections.map(objection => (
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={8} className={classes.paper}>
              <Typography mb={3} variant="h5" className={classes.title}>
                {objection.title}
              </Typography>
              {objection.text.map(paragraph => (
                <Typography variant="subtitle1" component="p">
                  {paragraph}
                </Typography>
              ))}
            </Paper>
          </Grid>
          ))}
      </Grid>
      <CTA />
    </Container>
  );
}

export default About;