import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import Amplify from 'aws-amplify';
import awsExports from './aws-exports';

import '@fontsource/roboto';

Amplify.configure(awsExports);

ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root')
);
