import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const heroShotHeight = 400;

function HeroShot(props) {
    const { banner } = props;
    return (
        <Box minHeight={heroShotHeight}
            sx={{
                position: 'relative',
                color: '#fff',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundImage: `url(${banner.image})`,
            }}
        >
            <Box
                sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                backgroundColor: 'rgba(0,0,0,.4)',
                }}
            />
            <Grid container>
                <Grid item lg={5} md={6} sm={7} xs={12}>
                <Box
                    sx={{
                        position: 'relative',
                        p: { xs: 3, md: 6 },
                        pr: { md: 0 },
                    }}
                >
                    <Typography mb={4} component="h1" variant="h3" color="inherit" gutterBottom>
                        {banner.title}
                    </Typography>
                    <Typography variant="h5" color="inherit" paragraph>
                        {banner.subtitle}
                    </Typography>
                    <Button size='large' component={RouterLink} to="/contact" variant="contained" color="primary">
                        {banner.linkText}
                    </Button>
                </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

HeroShot.propTypes = {
    banner: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        linkText: PropTypes.string.isRequired,
    }).isRequired
};

export default HeroShot;