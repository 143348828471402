import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress, Container, Typography } from '@material-ui/core';
import { useState } from 'react';
import { API } from 'aws-amplify';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(5),
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    form: {
        marginTop: theme.spacing(3),
    },
    backdrop: {
        color: '#fff',
      },
  }));

  function Contact(props) {
      const classes = useStyles();
      const [inquiry, setInquiry] = useState({});
      const [validEmail, setValidEmail] = useState(true);
      const [submitting, setSubmitting] = useState(false);

      /* TODO: this is a fix for when router link takes me to the middle of the contact page
            https://stackoverflow.com/questions/58431946/why-does-my-react-router-link-bring-me-to-the-middle-of-a-page
      
      const { location } = this.props;
      if (location.pathname !== prevProps.location.pathname) {
        window.scrollTo(0, 0);
      }*/

      async function handleFormSubmit(e) {
        e.preventDefault();
        if (!validEmail) {
            return;
        }

        setSubmitting(true);

        const options = {
            headers: {'Content-Type': 'application/json'},
            body: inquiry,
        };        
        API.post("blogapi", "/memo", options)
            .then(displayTempConfBox)
            .catch(console.log);
      }

      async function setName(e) {
        e.preventDefault();
        const modifiedInquiry = {...inquiry};
        modifiedInquiry.name = e.target.value;
        setInquiry(modifiedInquiry);
      }

      async function setEmail(e) {
        e.preventDefault();
        const modifiedInquiry = {...inquiry};
        modifiedInquiry.email = e.target.value;
        setInquiry(modifiedInquiry);

        setValidEmail(await isValidEmail(modifiedInquiry.email));
      }

      async function isValidEmail(email) {
        return Yup.string()
        .email('Invalid email')
        .required('Required')
        .isValid(email);   
      };

      async function setMemo(e) {
        e.preventDefault();
        const modifiedInquiry = {...inquiry};
        modifiedInquiry.memo = e.target.value;
        setInquiry(modifiedInquiry);
      }

      const displayTempConfBox = () => {
        console.log("TODO: to be implemented!");
        setTimeout(() => {
            props.history.push('/');
        }, 0);
      };
      
      return (
        <Container className={classes.root} maxWidth="xs">
          <div className={classes.paper}>
            <Typography component="h2" variant="h5">
                Hey, let's talk
            </Typography>

            <Backdrop className={classes.backdrop} open={submitting}>
                <CircularProgress color="inherit" />
            </Backdrop>   
            
            <form onSubmit={handleFormSubmit} className={classes.form} autoComplete="off">
                <TextField
                    value={inquiry.name || ''}
                    onChange={setName}
                    id="nameInput"
                    label="Name"
                    autoFocus
                    fullWidth
                    sx={{marginTop: 1}}
                />
                <TextField value={inquiry.email || ''}
                    onChange={setEmail}
                    id="emailInput"
                    label="Email"
                    fullWidth
                    required
                    sx={{marginTop: 1}}
                    error={validEmail ? undefined : true}
                    helperText={validEmail ? undefined : "Invalid email"}
                />
                <TextField
                    id="inquiryTextArea"
                    label="Memo"
                    multiline
                    rows={5}
                    variant="outlined"
                    value={inquiry.memo || ''}
                    onChange={setMemo}
                    fullWidth
                    sx={{marginTop: 1}}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{marginTop: 1}}
                >
                    Send
                </Button>
            </form>
          </div>
        </Container>
      );
}

export default Contact;