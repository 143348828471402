import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Amplify from 'aws-amplify';
import CssBaseline from '@material-ui/core/CssBaseline';

import Nav from './Nav';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Footer from './blog/Footer';

import awsExports from '../aws-exports';

Amplify.configure(awsExports);

const topMenuLinks = [
    { title: 'Home', url: '/' },
    { title: 'About', url: '/about' },
    { title: 'Contact', url: '/contact' },
  ];

function App() {
    return (
        <>
            <CssBaseline />
            <Router>
                <Nav links={topMenuLinks}/>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/about" component={About} />
                    <Route path="/contact" component={Contact} />
                </Switch>
                <Footer />
            </Router>
        </>
    );
}

export default App;