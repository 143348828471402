import React from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const painPoints = [
  {
    title: 'Content Manager',
    description: "In need of content tailored to your audience and not a generic diluted meaningless one-size-fits-all kind targeting EVERYONE and failing to appeal to ANYONE?",
    buttonVariant: 'outlined',
    imageUrl: "/images/Overwhelmed_content_manager.jpg",
    imageHeight:"200",
    imageAlt:"",
  },
  {
    title: 'Solopreneur',
    description: "Running a one-man show trying to handle marketing and gazillion other things all by yourself?",
    buttonVariant: 'outlined',
    imageUrl: "/images/Exhausted_solo_entrepreneur.jpg",
    imageHeight:"200",
    imageAlt:"",
  },
  {
    title: 'Stressed-out Dev',
    description: "Trying to scribble user guides, translate intricate technical concepts into plain English, and compile SME’s notes to comprehensible content?",
    buttonVariant: 'outlined',
    imageUrl: "/images/Stressed_out_developer.jpg",
    imageHeight:"200",
    imageAlt:"",
  },
];

function PainPoints() {

  return (
    <>
      <CssBaseline />
      <Box my={10}>
          <Typography component="h2" variant="h2" align="center" color="textPrimary" gutterBottom>
          Identify yourself
          </Typography>
      </Box>
      <Container maxWidth="lg">
        <Grid container spacing={12} justifyContent="space-around" alignItems="flex-start">
          {painPoints.map((painPoint) => (
            <Grid item key={painPoint.title} xs={12} sm={6} md={4}>
              <Card raised>
                <CardMedia 
                  component="img"
                  alt={painPoint.imageAlt}
                  height={painPoint.imageHeight}
                  image={painPoint.imageUrl}
                  title={painPoint.title}
                />
                <CardContent>
                  <Typography component="h2" variant="h4" align="center" color="textPrimary">
                    {painPoint.title}
                  </Typography>
                  
                  <Typography component="p" variant="subtitle1" align="center" color="textSecondary">
                      {painPoint.description}
                  </Typography>
                  
                </CardContent>
                <CardActions>
                  
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}

export default PainPoints;