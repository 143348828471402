/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "blogapi",
            "endpoint": "https://94jhxjxoj4.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "blogpost-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "contact-prod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
