import { Box, Container, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        backgroundColor: "#CECECE",
      },
    logo: {
        textAlign: "center",
    }
}));

const logos = [
    {name: "Testlio", image: "/images/Testlio_logo_for_RashidMa.webp"},
    {name: "BetterProgramming", image: "/images/BetterProgrammingLogo.png"},
    {name: "GitConnected", image: "/images/GitConnectedLogo.png"},
    {name: "Steampunk", image: "/images/xlab_steampunk_logo.svg"},
];

function PartnerStripe() {
    const classes = useStyles();
    return (
        <Container className={classes.root} maxWidth="xl">
        <Grid container alignItems="flex-end" alignContent="space-around" justify="space-around" spacing={2}>
            {logos.map(logo => (
                <Grid item key={logo.name} md={3} sm={6} xs={12}>
                    <Box className={classes.logo}>
                        <img src={logo.image} heigh="50" width="178" alt="" />
                    </Box>
                </Grid>
            ))}
            
        </Grid>
      </Container>
    )
}

export default PartnerStripe;