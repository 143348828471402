import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  root: {
    marginTop: theme.spacing(15),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.grey[300],
  },
}));

const offers = [
  {
    title: 'Web Copy',
    description: [
        'Carefully-crafted unique value proposition',
        'Exclusive brand story',
        'Compelling and high-converting copy',
        'Derived differentiators',
        'Benefit-driven product and service pages'
    ],
    buttonVariant: 'outlined',
  },
  {
    title: 'Compelling Content',
    subheader: 'With Research and SEO',
    description: [
        'Well-researched and accurate',
        'Concise, clear, and engaging',
        'Written to be skimmable',
        'Fluff-free copy',
        'Tailored specifically for your audience',
    ],
    buttonVariant: 'contained',
  },
  {
    title: 'Tech Copy',
    description: [
        'User guides, tutorials, informative articles',
        'SME-level documentation',
        'Complex concepts in plain English',
        'Technical in nature, digestable in reading',
        'Positions you as indisputable authority',
    ],
    buttonVariant: 'outlined',
  },
];

function Offers() {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Container className={classes.root} mt={4} maxWidth="lg">
        <Grid container spacing={12} justifyContent="space-around" alignItems="flex-end">
          {offers.map((offer) => (
            <Grid item key={offer.title} xs={12} sm={6} md={4}>
              <Card raised>
                <CardHeader
                  title={offer.title}
                  subheader={offer.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <ul>
                    {offer.description.map((line) => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button component={RouterLink} to="/contact" fullWidth variant={offer.buttonVariant} color="primary">
                    Let's Talk
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}

export default Offers;