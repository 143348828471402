import { Container } from "@material-ui/core";
import HeroShot from "./HeroShot";
import PartnerStripe from "./PartnerStripe";
import Offers from "./Offers";
import Assurance from "./Assurance";
import Introduction from "./Introduction";
import PainPoints from './PainPoints';

const heroShotParams = {
  title: 'Simplifying Software Copywriting',
  subtitle:
    "Engaging Content & Compelling Copy for Software Startups",
  image: '/images/RashidMa_banner.jpg',
  linkText: 'Let\'s talk',
};

function Home() {
  return (
    <Container maxWidth="xl" disableGutters>
      <HeroShot banner={heroShotParams} />
      <PartnerStripe />
      <PainPoints />
      <Assurance />
      <Introduction />
      <Offers />
    </Container>
  );
}

export default Home;