//import PropTypes from 'prop-types';
import { Link as RouterLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    logo: {
        flexGrow: 1,
    },
  }));

function Nav(props) {

    const { links } = props;

    const classes = useStyles();
      
    return (
            <AppBar position="static" variant="dense">
                <Toolbar
                    component="nav"
                    variant="regular"
                    sx={{ justifyContent: 'flex-end', overflowX: 'auto' }}
                >
                    <Typography variant="h5" className={classes.logo}>
                        Rashid Ma
                    </Typography>
                    {links.map((link) => (
                        <Link
                            component={RouterLink}
                            to={link.url}
                            color="inherit"
                            noWrap
                            key={link.title}
                            variant="body2"
                            sx={{ p: 1, flexShrink: 0 }}
                        >
                            {link.title}
                        </Link>
                    ))}
                </Toolbar>
            </AppBar>
    );
}

/* TODO: fix this: Failed prop type: Invalid prop `links` of type `array` supplied to `Nav`, expected `object`
Nav.propTypes = {
    links: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired
};*/

export default Nav;